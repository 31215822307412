import React, { useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { Csku, Typography } from "@commonsku/styles";
import { useTooltipAttributes } from "../../../../hooks";

type TColumn = typeof BASE_COLUMN_TYPES.Quantity;
const QuantityCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;

  const { amount, tooltipText } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) throw new Error("QuantityCell requires dataTransform");

    const { amount } = dataTransform(row.original[accessorKey]);

    return {
      amount,
    };
  }, [accessorKey, dataTransform, row.original]);

  const tooltipAttributes = useTooltipAttributes(tooltipText ?? amount, REPORT_TOOLTIP_TARGET_ID);

  return (
    <Csku className={className} onClick={onClick}>
      <div style={style} {...tooltipAttributes}>
        <Typography.Span
          style={{
            display: "block",
            alignContent: "center",
            ...textStyle,
          }}
        >
          {amount}
        </Typography.Span>
      </div>
    </Csku>
  );
};

export default QuantityCell;
